import { Alert, Box, Typography, useTheme } from "@mui/material";
import {
  availableStatusColors,
  DateFormField,
  FormField,
  FormFields,
  FormRow,
  FormRowHeader,
  IdentifierBadge,
  ProgressFormField,
  SingleRadioStatusIndicatorFormField,
  TextFormField,
} from "@pimo/pimo-components";
import { ACTIVITY_STATUS_VALUES, ActivityUpdateDTO } from "iam-types";
import { FC, useState } from "react";
import { useFormContext } from "react-hook-form";

interface ActivityFormRowProps {
  /** the activity object */
  activity: ActivityUpdateDTO;
  /** index number */
  index: number;
  activitiesIndex: number;
}

export const ActivityFormRow: FC<ActivityFormRowProps> = ({
  activity,
  index,
  activitiesIndex,
}) => {
  const { setValue, getValues } = useFormContext();
  const theme = useTheme();
  function markActivityAsEdited(activitiesIndex: number, index: number) {
    setValue(
      `activitiesGroupedBySubdimension.${activitiesIndex}.activities.${index}.isEdited`,
      true
    );
  }
  const [showError, setShowError] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  return (
    <FormRow data-testid="activityFormRow">
      <FormRowHeader
        sx={{
          justifyContent: "space-between",
          width: "100%",
          alignContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignContent: "center",
            lineHeight: "1rem",
          }}
        >
          <IdentifierBadge>{activity.activityId ?? ""}</IdentifierBadge>
          <Typography>{activity.description}</Typography>
        </Box>
        {showError && (
          <Alert variant="filled" severity="error">
            Progress 100 should always be associated with status "completed"
          </Alert>
        )}
        {showWarning && (
          <Alert variant="filled" severity="warning">
            Status is "At Risk". Please provide a comment to describe the risk
            and mitigation actions.
          </Alert>
        )}
      </FormRowHeader>
      <FormFields>
        <FormField label={"Activity Progress"}>
          <ProgressFormField
            name={`activitiesGroupedBySubdimension.${activitiesIndex}.activities.${index}.progress`}
            onValueChange={(value) => {
              markActivityAsEdited(activitiesIndex, index);
              /**
               * if progress new value is 100 then set status to completed, but if progress new value is not 100
               * and status is completed show an error
               */
              value === 100 &&
                setValue(
                  `activitiesGroupedBySubdimension.${activitiesIndex}.activities.${index}.status`,
                  "completed"
                );
              value !== 100 &&
              getValues(
                `activitiesGroupedBySubdimension.${activitiesIndex}.activities.${index}.status`
              ) === "completed"
                ? setShowError(true)
                : setShowError(false);
            }}
          />
        </FormField>
        <FormField label={"Status"}>
          <SingleRadioStatusIndicatorFormField
            name={`activitiesGroupedBySubdimension.${activitiesIndex}.activities.${index}.status`}
            values={[
              {
                value: ACTIVITY_STATUS_VALUES[0],
                label: ACTIVITY_STATUS_VALUES[0],
                color: "lightgray",
              },
              {
                value: ACTIVITY_STATUS_VALUES[1],
                label: ACTIVITY_STATUS_VALUES[1],
                color: availableStatusColors.GREEN,
              },
              {
                value: ACTIVITY_STATUS_VALUES[2],
                label: ACTIVITY_STATUS_VALUES[2],
                color: availableStatusColors.YELLOW,
              },
              {
                value: ACTIVITY_STATUS_VALUES[3],
                label: ACTIVITY_STATUS_VALUES[3],
                color: theme.palette.primary.main,
              },
            ]}
            handleChange={(value) => {
              markActivityAsEdited(activitiesIndex, index);
              /**
               * if status new value is completed then set progress to 100, but if status new value is not completed
               * and progress is 100 show an error
               */
              value === "completed" &&
                setValue(
                  `activitiesGroupedBySubdimension.${activitiesIndex}.activities.${index}.progress`,
                  100
                );
              value !== "completed" &&
              getValues(
                `activitiesGroupedBySubdimension.${activitiesIndex}.activities.${index}.progress`
              ) === 100
                ? setShowError(true)
                : setShowError(false);
              value === "at risk"
                ? setShowWarning(true)
                : setShowWarning(false);
            }}
          />
        </FormField>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: 1,
          }}
        >
          {/* <InputFormField
            label={"Activity Owner"}
            name={`activitiesGroupedBySubdimension.${activitiesIndex}.activities.${index}.owner`}
            onValueChange={() => {
              markActivityAsEdited(activitiesIndex, index);
            }}
          /> */}
          <DateFormField
            onValueChange={() => markActivityAsEdited(activitiesIndex, index)}
            label={"Start Date"}
            name={`activitiesGroupedBySubdimension.${activitiesIndex}.activities.${index}.startDate`}
          />
          <DateFormField
            onValueChange={() => markActivityAsEdited(activitiesIndex, index)}
            label={"Planned Completion Date"}
            name={`activitiesGroupedBySubdimension.${activitiesIndex}.activities.${index}.completionDate`}
          />
        </Box>

        <TextFormField
          onValueChange={() => markActivityAsEdited(activitiesIndex, index)}
          label={"Comment"}
          rows={5}
          multiline
          name={`activitiesGroupedBySubdimension.${activitiesIndex}.activities.${index}.comment`}
        />
      </FormFields>
    </FormRow>
  );
};
