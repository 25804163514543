import {
  areAllActivitiesCompleted,
  getReportingStatus,
  IAM_COLORS,
  roundToTheNearestTenth,
} from "iam-utils";
import type PptxGenJS from "pptxgenjs";

import { iamTheme } from "../../theme";
import {
  fetchDashboardCalculations,
  fetchOEProjects,
  fetchProgram,
} from "../fetch-helper";
import {
  getCurrentAndPreviousQuarterMonths,
  getMaturityColor,
  getMaturityFromLevel,
  mapToRange,
} from "./helper-functions";

export async function buildFourthSlidePPT(pptx: PptxGenJS) {
  const fourthSlide = pptx.addSlide();
  const previousAndCurrentMonth = getCurrentAndPreviousQuarterMonths();

  const dashboard = await fetchDashboardCalculations();
  const oeProjects = await fetchOEProjects();
  const program = await fetchProgram();
  fourthSlide.addText("IAM Rollout Tracking |", {
    x: 0.5,
    y: 0.65,
    fontSize: 20,
    fontFace: "Arial",
    color: iamTheme.palette.primary.main,
  });
  fourthSlide.addText(
    `as of ${new Date().toLocaleDateString(undefined, { day: "2-digit", month: "long", year: "numeric" })}`,
    {
      x: 7.5,
      y: 0.3,
      fontSize: 10,
      fontFace: "Arial",
      color: iamTheme.palette.primary.main,
    }
  );
  fourthSlide.addText(
    `Status ${["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][previousAndCurrentMonth.currentMonth]} ${previousAndCurrentMonth.currentYear}`,
    {
      x: 3.25,
      y: 0.65,
      fontFace: "Arial",
      fontSize: 20,
      color: iamTheme.palette.primary.main,
    }
  );

  fourthSlide.addText("Update on Strategic IAM Transformation", {
    x: 0.5,
    y: 0.3,
    fontSize: 11,
    fontFace: "Arial",
    bold: true,
    color: iamTheme.palette.primary.main,
  });

  fourthSlide.addText("Coverage", {
    x: 0.5,
    y: 1,
    fontSize: 14,
    fontFace: "Arial",
    w: 4,
    color: iamTheme.palette.primary.main,
    bold: true,
  });

  fourthSlide.addShape(pptx.ShapeType.line, {
    x: 0.5,
    y: 1.1,
    w: 4,
    h: 0,
    line: {
      color: iamTheme.palette.primary.main,
      width: 1,
      dashType: "solid",
    },
  });
  const chartData4 = [
    {
      name: "# OEs updated",
      labels: [""], // Single category
      values: [
        oeProjects.data.filter(
          (oe) =>
            getReportingStatus({
              lastUpdatedDateInStringFormat: oe.attributes.oeEarliestUpdatedAt,
              endReportingDateInStringFormat:
                program?.data.attributes.endReportingDate,
              startReportingDateInStringFormat:
                program?.data.attributes.startReportingDate,
              reportingFrequency: program?.data.attributes.reportingFrequency,
              conditionUponWhichToReturnGreenByDefault:
                areAllActivitiesCompleted(oe.attributes.activities.data ?? []),
            }).color === IAM_COLORS.green
        ).length,
      ], // Value for updated OEs
      color: "#2F5597", // Dark blue for updated OEs
    },
    {
      name: "# OEs have not updated",
      labels: [""], // Single category
      values: [
        oeProjects.data.filter(
          (oe) =>
            getReportingStatus({
              lastUpdatedDateInStringFormat: oe.attributes.oeEarliestUpdatedAt,
              endReportingDateInStringFormat:
                program?.data.attributes.endReportingDate,
              startReportingDateInStringFormat:
                program?.data.attributes.startReportingDate,
              reportingFrequency: program?.data.attributes.reportingFrequency,
              conditionUponWhichToReturnGreenByDefault:
                areAllActivitiesCompleted(oe.attributes.activities.data ?? []),
            }).color !== IAM_COLORS.green || !oe.attributes.oeEarliestUpdatedAt
        ).length,
      ], // Value for not updated OEs
      color: "#4F81BD", // Medium blue for not updated OEs
    },
  ];

  // Add the bar chart
  fourthSlide.addChart(pptx.ChartType.bar, chartData4, {
    x: 0.2, // X position on the slide
    y: 1.1, // Y position on the slide
    w: 3.7, // Width of the chart
    h: 2, // Height of the chart
    showLegend: true, // Show legend
    legendPos: "t", // Legend on the right
    showValue: true, // Show values on the bars
    barGapWidthPct: 25, // Gap between bars
    catAxisLineShow: false, // Hides the category axis grid lines
    valAxisLineShow: false, // Hides the value axis grid lines
    valGridLine: { color: "FFFFFF" }, // Optional: Explicitly set grid line color to invisible
    barDir: "horizontal", // Horizontal bars
    chartColors: chartData4.map((d) => d.color), // Use specified colors
    catAxisTitle: "", // No category axis title
    valAxisTitle: "", // No value axis title
    showTitle: false, // No chart title
    barGrouping: "stacked",
  });

  fourthSlide.addText("Average IAM Maturity Across all OEs", {
    x: 0.5,
    y: 3.2,
    fontSize: 14,
    fontFace: "Arial",
    bold: true,
    w: 4,
    color: iamTheme.palette.primary.main,
  });

  fourthSlide.addShape(pptx.ShapeType.line, {
    x: 0.5,
    y: 3.3,
    w: 4,
    h: 0,
    line: {
      color: iamTheme.palette.primary.main,
      width: 1,
      dashType: "solid",
    },
  });

  // Define updated dimensions and position

  // Define updated dimensions and position
  const colors = [
    getMaturityColor(1),
    getMaturityColor(2),
    getMaturityColor(3),
    getMaturityColor(4),
    getMaturityColor(5),
  ];
  const rectWidth = 0.5; // Standard width for middle rectangles
  const halfWidth = rectWidth / 2; // Half-width for the first and last rectangles
  const rectHeight = 0.5; // Height of the rectangle remains the same
  const slideWidth = 0; // Default slide width in inches
  const slideHeight = 5.63; // Default slide height in inches
  const startX = slideWidth / 2 + 1; // Start on the right half of the slide
  const startY = slideHeight / 2 + 1; // Bottom half of the slide

  // Define starting x-coordinate for the first rectangle
  let currentX = startX;

  // Add colored rectangles
  colors.forEach((color, index) => {
    let width = rectWidth; // Default width
    if (index === 0 || index === colors.length - 1) {
      width = halfWidth; // Apply half-width for the first and last rectangles
    }
    fourthSlide.addShape(pptx.ShapeType.rect, {
      x: currentX, // Position horizontally
      y: startY,
      w: width,
      h: rectHeight,
      fill: { color: color },
    });
    currentX += width; // Move to the next position based on the current rectangle width
  });

  fourthSlide.addShape(pptx.ShapeType.rect, {
    x: mapToRange(dashboard?.overallAverageMaturity.averageMaturityNumber ?? 1),
    y: 3.7,
    w: 0.02,
    h: 1,
    fill: {
      color: "#000000",
    },
  });

  fourthSlide.addText(
    `+ ${
      (dashboard?.overallAverageMaturity.averageMaturityNumber ?? 1) -
      roundToTheNearestTenth(
        dashboard?.trends?.overallActuals.find(
          (t) =>
            t.month === previousAndCurrentMonth.previousMonth &&
            t.year === previousAndCurrentMonth.previousYear
        )?.maturity ?? 1
      )
    }`,
    {
      x: mapToRange(
        dashboard?.overallAverageMaturity.averageMaturityNumber ?? 1
      ),
      y: 4.2,
      h: 0.1,
      bold: true,
      fontSize: 12,
      fontFace: "Arial",
    }
  );

  fourthSlide.addText(
    `${getMaturityFromLevel(dashboard?.overallAverageMaturity.averageMaturityNumber ?? 1)}: ${dashboard?.overallAverageMaturity.averageMaturityNumber ?? 1}`,
    {
      x: mapToRange(
        dashboard?.overallAverageMaturity.averageMaturityNumber ?? 1
      ),
      y: 4.7,
      h: 0.1,
      bold: true,
      fontSize: 12,
      fontFace: "Arial",
    }
  );

  fourthSlide.addShape(pptx.ShapeType.rect, {
    x: mapToRange(
      dashboard?.trends?.overallActuals.find(
        (t) =>
          t.month === previousAndCurrentMonth.previousMonth &&
          t.year === previousAndCurrentMonth.previousYear
      )?.maturity ?? 1
    ),
    y: 3.7,
    w: 0.02,
    h: 1,
    fill: {
      color: "#cccccc",
    },
  });

  fourthSlide.addText(
    `${roundToTheNearestTenth(
      dashboard?.trends?.overallActuals.find(
        (t) =>
          t.month === previousAndCurrentMonth.previousMonth &&
          t.year === previousAndCurrentMonth.previousYear
      )?.maturity ?? 1
    )}`,
    {
      x: mapToRange(
        dashboard?.trends?.overallActuals.find(
          (t) =>
            t.month === previousAndCurrentMonth.previousMonth &&
            t.year === previousAndCurrentMonth.previousYear
        )?.maturity ?? 1
      ),
      y: 4.85,
      h: 0.1,
      color: "#cccccc",
      fontSize: 12,
      fontFace: "Arial",
    }
  );
  fourthSlide.addText(
    `${["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][previousAndCurrentMonth.previousMonth]} Value`,
    {
      x: 0.5,
      y: 5,
      h: 0.1,
      color: "#cccccc",
      fontSize: 12,
      fontFace: "Arial",
    }
  );
  fourthSlide.addText("Maturity Levels per OE", {
    x: 5,
    y: 1,
    fontSize: 14,
    w: 4,
    fontFace: "Arial",
    color: iamTheme.palette.primary.main,
    bold: true,
  });

  fourthSlide.addShape(pptx.ShapeType.line, {
    x: 5,
    y: 1.1,
    w: 4,
    h: 0,
    line: {
      color: iamTheme.palette.primary.main,
      width: 1,
      dashType: "solid",
    },
  });

  const chartData = [
    {
      name: `${["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"][previousAndCurrentMonth.previousMonth]} ${previousAndCurrentMonth.previousYear}`,
      labels: ["Very Low", "Low", "Medium", "High", "Very High"],
      color: "#C0C0C0", // Gray for Jul 24
      values: [
        oeProjects.data.filter((oe) =>
          oe.attributes.trends?.data.find(
            (t) =>
              t.attributes.month === previousAndCurrentMonth.previousMonth &&
              t.attributes.year === previousAndCurrentMonth.previousYear &&
              t.attributes.overallMaturity < 1.5
          )
        ).length,
        oeProjects.data.filter((oe) =>
          oe.attributes.trends?.data.find(
            (t) =>
              t.attributes.month === previousAndCurrentMonth.previousMonth &&
              t.attributes.year === previousAndCurrentMonth.previousYear &&
              t.attributes.overallMaturity < 2.5 &&
              t.attributes.overallMaturity >= 1.5
          )
        ).length,
        oeProjects.data.filter((oe) =>
          oe.attributes.trends?.data.find(
            (t) =>
              t.attributes.month === previousAndCurrentMonth.previousMonth &&
              t.attributes.year === previousAndCurrentMonth.previousYear &&
              t.attributes.overallMaturity < 3.5 &&
              t.attributes.overallMaturity >= 2.5
          )
        ).length,
        oeProjects.data.filter((oe) =>
          oe.attributes.trends?.data.find(
            (t) =>
              t.attributes.month === previousAndCurrentMonth.previousMonth &&
              t.attributes.year === previousAndCurrentMonth.previousYear &&
              t.attributes.overallMaturity < 4.5 &&
              t.attributes.overallMaturity >= 3.5
          )
        ).length,
        oeProjects.data.filter((oe) =>
          oe.attributes.trends?.data.find(
            (t) =>
              t.attributes.month === previousAndCurrentMonth.previousMonth &&
              t.attributes.year === previousAndCurrentMonth.previousYear &&
              t.attributes.overallMaturity >= 4.5
          )
        ).length,
      ],
    },
    {
      color: ["#FF0000", "#FFD700", "#FFFF00", "#00FF00", "#00FF00"],
      name: `${["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"][previousAndCurrentMonth.currentMonth]} ${previousAndCurrentMonth.currentYear}`,

      labels: ["Very Low", "Low", "Medium", "High", "Very High"],
      values: [
        oeProjects.data.filter(
          (oe) => (oe.attributes.overallAverageMaturityLevel ?? 1) < 1.5
        ).length,
        oeProjects.data.filter(
          (oe) =>
            (oe.attributes.overallAverageMaturityLevel ?? 1) < 2.5 &&
            (oe.attributes.overallAverageMaturityLevel ?? 1) >= 1.5
        ).length,

        oeProjects.data.filter(
          (oe) =>
            (oe.attributes.overallAverageMaturityLevel ?? 1) < 3.5 &&
            (oe.attributes.overallAverageMaturityLevel ?? 1) >= 2.5
        ).length,

        oeProjects.data.filter(
          (oe) =>
            (oe.attributes.overallAverageMaturityLevel ?? 1) < 4.5 &&
            (oe.attributes.overallAverageMaturityLevel ?? 1) >= 3.5
        ).length,
        oeProjects.data.filter(
          (oe) => (oe.attributes.overallAverageMaturityLevel ?? 1) >= 4.5
        ).length,
      ],
    },
  ];

  // Add the bar chart to the slide
  fourthSlide.addChart(pptx.ChartType.bar, chartData, {
    x: 4.8, // X position on the slide
    y: 1.1, // Y position on the slide
    w: 5, // Width of the chart
    h: 1.95, // Height of the chart
    barDir: "col", // Vertical bars
    catAxisLabelFontSize: 10, // Font size for categories
    catAxisLabelColor: "#1F497D", // Color for categories
    catAxisLineShow: false, // Hides the category axis grid lines
    valAxisLineShow: false, // Hides the value axis grid lines
    valGridLine: { color: "FFFFFF" }, // Optional: Explicitly set grid line color to invisible
    showLegend: true, // Show legend for Jul 24 and Aug 24
    legendPos: "r", // Legend position (right)
    showValue: true, // Show values on bars
    valAxisTitle: "", // No value axis title
    valAxisMinVal: 0, // Minimum value for the value axis
    valAxisMaxVal: 40, // Maximum value for the value axis
    valAxisMajorUnit: 10, // Major unit step
    barGrouping: "clustered", // Clustered bars
  });

  fourthSlide.addText("Status of Activities", {
    x: 5,
    y: 3.2,
    fontSize: 14,
    bold: true,
    fontFace: "Arial",
    w: 4,
    color: iamTheme.palette.primary.main,
  });

  fourthSlide.addShape(pptx.ShapeType.line, {
    x: 5,
    y: 3.3,
    w: 4,
    h: 0,
    line: {
      color: iamTheme.palette.primary.main,
      width: 1,
      dashType: "solid",
    },
  });

  // Define data for the doughnut chart
  const chartData2 = [
    {
      labels: ["Not started", "At risk", "On track", "Completed"],
      values: [
        dashboard?.numberOfActivitiesPerStatus?.["not started"] ?? 1,
        dashboard?.numberOfActivitiesPerStatus?.["at risk"] ?? 1,
        dashboard?.numberOfActivitiesPerStatus?.["on track"] ?? 1,
        dashboard?.numberOfActivitiesPerStatus?.["completed"] ?? 1,
      ],
    },
  ];

  // Add the doughnut chart to the slide
  fourthSlide.addChart(pptx.ChartType.doughnut, chartData2, {
    x: 7, // X position on the slide
    y: 3.2, // Y position on the slide
    w: 1.8, // Width of the chart
    h: 1.8, // Height of the chart
    showValue: false, // Hide values inside the doughnut
    holeSize: 70, // Create a larger inner hole
    dataLabelColor: "000000", // Optional, to set label color
    chartColors: ["D3D3D3", "FFD700", "#5ecd8a", "122b54"],
  });

  // Add legend with text descriptions and values
  const legendTexts = [
    {
      text: "Not started",
      color: "D3D3D3",
      percentage: `${Math.round(
        ((dashboard?.numberOfActivitiesPerStatus?.["not started"] ?? 1) /
          (dashboard?.numberOfActivities ?? 1)) *
          100
      )}%`,
    },
    {
      text: "At risk",
      color: "#fab600",
      percentage: `${Math.round(
        ((dashboard?.numberOfActivitiesPerStatus?.["at risk"] ?? 1) /
          (dashboard?.numberOfActivities ?? 1)) *
          100
      )}%`,
    },
    {
      text: "On track",
      color: "5ecd8a",
      percentage: `${Math.round(
        ((dashboard?.numberOfActivitiesPerStatus?.["on track"] ?? 1) /
          (dashboard?.numberOfActivities ?? 1)) *
          100
      )}%`,
    },
    {
      text: "Completed",
      color: "#122b54",
      percentage: `${Math.round(
        ((dashboard?.numberOfActivitiesPerStatus?.["completed"] ?? 1) /
          (dashboard?.numberOfActivities ?? 1)) *
          100
      )}%`,
    },
  ];

  legendTexts.forEach((item, index) => {
    fourthSlide.addShape(pptx.ShapeType.rect, {
      x: 5,
      y: 3.65 + index * 0.3,
      w: 0.2,
      h: 0.2,
      fill: { color: item.color },
    });

    fourthSlide.addText(item.text, {
      x: 5.3,
      y: 3.75 + index * 0.3,
      fontSize: 12,
      fontFace: "Arial",
      color: "003366",
    });

    fourthSlide.addText(item.percentage, {
      x: 6.3,
      y: 3.75 + index * 0.3,
      fontSize: 12,
      fontFace: "Arial",
      color: "003366",
    });
  });
}
