import { Box } from "@mui/material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import { TitleCard } from "@pimo/pimo-components";
import { FilterDataRegionFilter } from "iam-types";
import { fireFilterEventWithRegionTrigger } from "iam-utils";

import {
  ReportTitleCardDashboardSlot,
  type ReportTitleCardDashboardSlotProps,
} from "./report-title-card-dashboard-slot";
import {
  ReportTitleCardEditSlot,
  type ReportTitleCardEditSlotProps,
} from "./report-title-card-edit-slot";

type PropsWithoutEventHandler<T> = Omit<T, `on${string}`>;

export type ReportTitleCardProps = {
  title: string;
  filterData?: FilterDataRegionFilter;
  filterValues?: {
    regions?: string[];
  };
  fireEvent?: (
    event: "edit-button:click"
  ) =>
    | void
    | ((
        event: "filter:clear" | "filter:apply",
        payload?: FilterDataRegionFilter
      ) => void);
} & (
  | PropsWithoutEventHandler<ReportTitleCardEditSlotProps>
  | PropsWithoutEventHandler<ReportTitleCardDashboardSlotProps>
);

export const ReportTitleCard: PimoReactComponent<
  ReportTitleCardProps,
  "edit-button:click" | "filter:clear" | "filter:apply"
> = ({ fireEvent, filterData, filterValues, title, ...props }) => (
  <TitleCard
    title={title}
    endSlot={
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {(props as ReportTitleCardEditSlotProps).members ||
        (props as ReportTitleCardEditSlotProps).showEditButton ? (
          <ReportTitleCardEditSlot
            {...(props as ReportTitleCardEditSlotProps)}
            onClick={() => fireEvent?.("edit-button:click")}
          />
        ) : (
          <ReportTitleCardDashboardSlot
            {...(props as ReportTitleCardDashboardSlotProps)}
            clearFilters={() => fireEvent?.("filter:clear")}
            filterValues={filterValues}
            filterData={filterData}
            handleFilterChange={(event, trigger) => {
              fireFilterEventWithRegionTrigger(
                trigger,
                filterData,
                event,
                fireEvent
              );
            }}
          />
        )}
      </Box>
    }
  />
);
