import type { Log } from "@pimo/pimo-components";

import { STRAPI_URL } from "../env";

export async function fetchLogs(params?: {
  reportId?: number | undefined;
  offset: number;
  limit: number;
}): Promise<Log[]> {
  try {
    // Convert offset and limit to numbers (with defaults)
    const offset = Number(params?.offset ?? 0);
    const limit = Number(params?.limit ?? 50);
    const baseUrl = `${STRAPI_URL}/api/bff-logs`;

    const url = new URL(baseUrl);

    // Create query parameters using URLSearchParams
    const searchParams = new URLSearchParams({
      offset: offset.toString(),
      limit: limit.toString(),
      reportId: params?.reportId?.toString() ?? "",
    });
    url.search = searchParams.toString();
    const response = await fetch(url.toString(), {
      credentials: "include",
    });

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    return (await response.json()) as Log[];
  } catch {
    return [] as Log[];
  }
}
