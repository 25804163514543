export function getLast12Months(
  date: Date
): { month: string; monthNumber: number; year: number }[] {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const result: { month: string; monthNumber: number; year: number }[] = [];

  for (let i = 1; i < 13; i++) {
    const tempDate = new Date(date);
    tempDate.setMonth(date.getMonth() - i);

    result.unshift({
      month: `${months[tempDate.getMonth()]} ${String(tempDate.getFullYear()).slice(-2)}`, // e.g., "Jan 24"
      monthNumber: tempDate.getMonth(),
      year: tempDate.getFullYear(),
    });
  }

  return result;
}

export function getEndOfQuartersBetweenDates(
  startDate: Date,
  endDate: Date
): { number: number; year: number; quarter: string }[] {
  const result: { number: number; year: number; quarter: string }[] = [];

  const currentDate = new Date(startDate);

  // Iterate through months between start and end dates
  while (currentDate <= endDate) {
    const month = currentDate.getMonth();
    const year = currentDate.getFullYear();

    // Check if the month is an end-of-quarter month (March, June, September, December)
    if ([2, 5, 8, 11].includes(month)) {
      result.push({
        number: month,
        year: year,
        quarter: `Q${Math.floor(month / 3) + 1} '${String(year).slice(-2)}`,
      });
    }

    // Move to the next month
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return result;
}

export function mapToRange(value: number): number {
  if (value < 1 || value > 5) {
    throw new Error("Value must be between 1 and 5");
  }

  // Define breakpoints for input-output mapping
  const mappings = [
    { input: 1, output: 1 },
    { input: 1.5, output: 1.25 },
    { input: 2.5, output: 1.75 },
    { input: 3.5, output: 2.25 },
    { input: 4.5, output: 2.75 },
    { input: 5, output: 3 },
  ];

  // Find the two closest points for the segment containing `value`
  for (let i = 0; i < mappings.length - 1; i++) {
    const lower = mappings[i];
    const upper = mappings[i + 1];

    if (value >= lower.input && value <= upper.input) {
      // Perform linear interpolation
      const ratio = (value - lower.input) / (upper.input - lower.input);
      return lower.output + ratio * (upper.output - lower.output);
    }
  }

  // Fallback (should never reach here if input is within range)
  throw new Error("Unexpected value");
}

export function getQuarter(date: Date) {
  const currentYear = date.getFullYear();
  const month = date.getMonth();

  if (month === 0 || month === 1 || month === 2)
    return {
      quarterAndYear: `Q1'${currentYear.toString().slice(-2)}`,
      month: 11,
      year: currentYear,
    };
  if (month === 3 || month === 4 || month === 5)
    return {
      quarterAndYear: `Q2'${currentYear.toString().slice(-2)}`,
      month: 2,
      year: currentYear,
    };
  if (month === 6 || month === 7 || month === 8)
    return {
      quarterAndYear: `Q3'${currentYear.toString().slice(-2)}`,
      month: 5,
      year: currentYear,
    };
  else
    return {
      quarterAndYear: `Q4'${currentYear.toString().slice(-2)}`,
      month: 8,
      year: currentYear,
    };
}
export function getMaturityColor(level: number) {
  switch (level) {
    case 5:
      return "#65ac57"; // Dark Green
    case 4:
      return "#a7ce5c"; // Light Green
    case 3:
      return "#fdfe3f"; // Yellow
    case 2:
      return "#ecc02f"; // Orange
    case 1:
      return "#ff0000"; // Red
    default:
      return "cccccc"; // Gray
  }
}

export function getCurrentAndPreviousQuarterMonths() {
  const date = new Date();
  const currentMonth = date.getMonth();
  if (currentMonth === 0) {
    return {
      currentMonth: 11,
      previousMonth: 8,
      currentYear: date.getFullYear() - 1,
      previousYear: date.getFullYear() - 1,
    };
  } else if (currentMonth === 1 || currentMonth === 2) {
    return {
      currentMonth: currentMonth,
      previousMonth: 11,
      currentYear: date.getFullYear(),
      previousYear: date.getFullYear() - 1,
    };
  } else if (currentMonth === 3 || currentMonth === 4 || currentMonth === 5) {
    return {
      currentMonth: currentMonth,
      previousMonth: 2,
      currentYear: date.getFullYear(),
      previousYear: date.getFullYear(),
    };
  } else if (currentMonth === 6 || currentMonth === 7 || currentMonth === 8) {
    return {
      currentMonth: currentMonth,
      previousMonth: 5,
      currentYear: date.getFullYear(),
      previousYear: date.getFullYear(),
    };
  } else {
    return {
      currentMonth: currentMonth,
      previousMonth: 8,
      currentYear: date.getFullYear(),
      previousYear: date.getFullYear(),
    };
  }
}

export function getMaturityFromLevel(level: number) {
  const roundedNumber = Math.round(level);
  switch (roundedNumber) {
    case 1:
      return "Very Low";
    case 2:
      return "Low";
    case 3:
      return "Medium";
    case 4:
      return "High";
    case 5:
      return "Very High";
    default:
      return "Very Low";
  }
}

interface DivisionItem {
  attributes: {
    division?: string | null;
  };
}

export function sortByDivision(a: DivisionItem, b: DivisionItem): number {
  const getDivisionNumber = (division: string | null | undefined): number => {
    const match = division?.match(/[A-Z](\d+)/);
    return match ? parseInt(match[1]) : 99;
  };

  return (
    getDivisionNumber(a.attributes.division) -
    getDivisionNumber(b.attributes.division)
  );
}
