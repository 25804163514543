import type PptxGenJS from "pptxgenjs";

import { iamTheme } from "../../theme";
import { fetchDashboardCalculations } from "../fetch-helper";
import {
  getCurrentAndPreviousQuarterMonths,
  getQuarter,
} from "./helper-functions";
export async function buildSecondSlide(pptx: PptxGenJS) {
  const currentQuarter = getQuarter(new Date());

  const secondSlide = pptx.addSlide();
  const dashboard = await fetchDashboardCalculations();

  const dataChartLine = [
    {
      name: "Actual",
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      values:
        dashboard?.trends?.overallActuals
          .filter((t) => t.year === currentQuarter.year)
          .map((t) => t.maturity) ?? [],
    },
    {
      name: "Planned",
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      values:
        dashboard?.trends?.overallPlanned
          .filter((t) => t.year === currentQuarter.year)
          .map((t) => t.maturity) ?? [],
    },
    {
      name: "Minimum Value",
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      values:
        dashboard?.trends?.recommendedMaturitiesOverall
          .filter((t) => t.year === currentQuarter.year)
          .map((t) => t.maturity) ?? [],
    },
  ];

  const modelDataChartLine = [
    {
      name: "Actual",
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      values:
        dashboard?.trends?.modelActuals
          .filter((t) => t.year === currentQuarter.year)
          .map((t) => t.maturity) ?? [],
    },
    {
      name: "Planned",
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      values:
        dashboard?.trends?.modelPlanned
          .filter((t) => t.year === currentQuarter.year)
          .map((t) => t.maturity) ?? [],
    },
    {
      name: "Minimum Value",
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      values:
        dashboard?.trends?.recommendedMaturitiesOverall
          .filter((t) => t.year === currentQuarter.year)
          .map((t) => t.maturity) ?? [],
    },
  ];

  const techDataChartLine = [
    {
      name: "Actual",
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      values:
        dashboard?.trends?.techActuals
          .filter((t) => t.year === currentQuarter.year)
          .map((t) => t.maturity) ?? [],
    },
    {
      name: "Planned",
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      values:
        dashboard?.trends?.techPlanned
          .filter((t) => t.year === currentQuarter.year)
          .map((t) => t.maturity) ?? [],
    },
    {
      name: "Minimum Value",
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      values:
        dashboard?.trends?.recommendedMaturitiesOverall
          .filter((t) => t.year === currentQuarter.year)
          .map((t) => t.maturity) ?? [],
    },
  ];

  const govDataChartLine = [
    {
      name: "Actual",
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      values:
        dashboard?.trends?.govActuals
          .filter((t) => t.year === currentQuarter.year)
          .map((t) => t.maturity) ?? [],
    },
    {
      name: "Planned",
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      values:
        dashboard?.trends?.govPlanned
          .filter((t) => t.year === currentQuarter.year)
          .map((t) => t.maturity) ?? [],
    },
    {
      name: "Minimum Value",
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      values:
        dashboard?.trends?.recommendedMaturitiesOverall
          .filter((t) => t.year === currentQuarter.year)
          .map((t) => t.maturity) ?? [],
    },
  ];

  secondSlide.addText("IAM Rollout Tracking |", {
    x: 0.5,
    y: 0.65,
    fontSize: 20,
    fontFace: "Arial",
    color: iamTheme.palette.primary.main,
  });
  const previousAndCurrentMonth = getCurrentAndPreviousQuarterMonths();
  secondSlide.addText(
    `Group Trend ${["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][previousAndCurrentMonth.previousMonth]} ${previousAndCurrentMonth.previousYear}`,
    {
      x: 3.25,
      y: 0.65,
      fontSize: 20,
      fontFace: "Arial",
      color: iamTheme.palette.primary.main,
    }
  );
  secondSlide.addText(
    `as of ${new Date().toLocaleDateString(undefined, { day: "2-digit", month: "long", year: "numeric" })}`,
    {
      x: 7.5,
      y: 0.3,
      fontSize: 10,
      fontFace: "Arial",
      color: iamTheme.palette.primary.main,
    }
  );
  secondSlide.addText("Transformational Journey", {
    x: 0.5,
    y: 0.3,
    fontSize: 11,
    fontFace: "Arial",
    bold: true,
    color: iamTheme.palette.primary.main,
  });

  secondSlide.addText(
    "This slide is missing the comments on the trends lines above and below the Overall trend chart",
    {
      x: 8,
      y: 1,
      w: 2,
      fontSize: 11,
      fontFace: "Arial",
      bold: true,
      color: iamTheme.palette.primary.main,
    }
  );

  // Add line chart to the slide
  secondSlide.addChart(pptx.ChartType.line, dataChartLine, {
    x: 0, // X position on the slide
    y: 1.1, // Y position on the slide
    w: 9.7, // Width of the chart
    h: 1.9, // Height of the chart
    showLabel: true,
    valAxisMinVal: 1,
    valAxisMaxVal: 5,
    showValue: true,
    title: "Iam Group Trend Overall", // Title of the chart
    titleFontSize: 12,
    titleColor: iamTheme.palette.primary.main,
    valAxisTitle: "Maturity Level", // Value axis label
    valAxisTitleColor: iamTheme.palette.primary.main,
    color: iamTheme.palette.primary.main,
    chartColors: ["#00b2af", "#2e96ff", "#b807d5"],
    showTitle: true,
    showLegend: false, // Show legend
    showValAxisTitle: true,
    dataLabelFormatCode: "0.0",
    dataLabelPosition: "t",
    dataLabelFontSize: 10,
    lineSize: 2,
    valAxisLabelColor: iamTheme.palette.primary.main,
    valAxisTitleFontSize: 10,
  });

  secondSlide.addChart(pptx.ChartType.line, modelDataChartLine, {
    x: 0, // X position on the slide
    y: 3.2, // Y position on the slide
    w: 3.4, // Width of the chart
    h: 1.9, // Height of the chart
    showLabel: true,
    dataLabelFormatCode: "0.0",
    valAxisMinVal: 1,
    valAxisMaxVal: 5,
    title: "Iam Model Trend Overall", // Title of the chart
    titleFontSize: 10,
    titleColor: iamTheme.palette.primary.main,
    valAxisTitle: "Maturity Level", // Value axis label
    showTitle: true,
    chartColors: ["#00b2af", "#2e96ff", "#b807d5"],
    showLegend: false, // Show legend
    showValAxisTitle: true,
    valAxisLabelColor: iamTheme.palette.primary.main,
    valAxisTitleColor: iamTheme.palette.primary.main,
    valAxisTitleFontSize: 8,
    dataLabelPosition: "t",
    dataLabelFontSize: 9,
    catAxisLabelFontSize: 8,
    lineSize: 2,
    showValue: true,
  });

  secondSlide.addChart(pptx.ChartType.line, techDataChartLine, {
    x: 3.15, // X position on the slide
    y: 3.2, // Y position on the slide
    w: 3.4, // Width of the chart
    h: 1.9, // Height of the chart
    showLabel: true,
    valAxisMinVal: 1,
    valAxisMaxVal: 5,
    dataLabelPosition: "t",
    title: "Iam Gov Trend Overall", // Title of the chart
    titleFontSize: 10,
    titleColor: iamTheme.palette.primary.main,
    valAxisTitle: "Maturity Level", // Value axis label
    showTitle: true,
    chartColors: ["#00b2af", "#2e96ff", "#b807d5"],
    showLegend: false, // Show legend
    showValAxisTitle: true,
    valAxisLabelColor: iamTheme.palette.primary.main,
    valAxisTitleColor: iamTheme.palette.primary.main,
    valAxisTitleFontSize: 8,
    catAxisLabelFontSize: 8,
    lineSize: 2,
    dataLabelFontSize: 9,
    serAxisLineShow: true,
    showValue: true,
    dataLabelFormatCode: "0.0",
  });

  secondSlide.addChart(pptx.ChartType.line, govDataChartLine, {
    x: 6.55, // X position on the slide
    y: 3.2, // Y position on the slide
    w: 3.4, // Width of the chart
    h: 1.9, // Height of the chart
    showLabel: true,
    valAxisMinVal: 1,
    valAxisMaxVal: 5,
    dataLabelFormatCode: "0.0",
    title: "Iam Tech Trend Overall", // Title of the chart
    titleFontSize: 10,
    dataLabelPosition: "t",
    titleColor: iamTheme.palette.primary.main,
    valAxisTitle: "Maturity Level", // Value axis label
    showTitle: true,
    chartColors: ["#00b2af", "#2e96ff", "#b807d5"],
    showLegend: false, // Show legend
    showValAxisTitle: true,
    valAxisLabelColor: iamTheme.palette.primary.main,
    dataLabelFontSize: 9,
    dataLabelColor: "#000000",
    valAxisTitleColor: iamTheme.palette.primary.main,
    valAxisTitleFontSize: 8,
    catAxisLabelFontSize: 8,
    lineSize: 2,
    showValue: true,
  });

  secondSlide.addShape(pptx.ShapeType.rect, {
    x: 0.5,
    y: 5.1,
    w: 0.1,
    h: 0.1,
    fill: {
      color: "#00b2af",
    },
  });

  secondSlide.addText("Actual", {
    x: 0.6,
    y: 5.1,
    w: 0.6,
    h: 0.1,
    fontSize: 8,
    fontFace: "Arial",
    color: iamTheme.palette.primary.main,
  });

  secondSlide.addShape(pptx.ShapeType.rect, {
    x: 1.3,
    y: 5.1,
    w: 0.1,
    h: 0.1,
    fill: {
      color: "#2e96ff",
    },
  });

  secondSlide.addText("Planned", {
    x: 1.4,
    y: 5.1,
    w: 0.7,
    fontFace: "Arial",
    h: 0.1,
    fontSize: 8,
    color: iamTheme.palette.primary.main,
  });
  secondSlide.addShape(pptx.ShapeType.rect, {
    x: 2.1,
    y: 5.1,
    w: 0.1,
    h: 0.1,
    fill: {
      color: "#b807d5",
    },
  });
  secondSlide.addText("Minimum Target", {
    x: 2.2,
    y: 5.1,
    w: 1.5,
    h: 0.1,
    fontSize: 8,
    fontFace: "Arial",
    color: iamTheme.palette.primary.main,
  });
}
