import type PptxGenJS from "pptxgenjs";

import { iamTheme } from "../../theme";
import { fetchDashboardCalculations, fetchOEProjects } from "../fetch-helper";
import {
  getEndOfQuartersBetweenDates,
  getLast12Months,
} from "./helper-functions";
export async function buildThirdSlidePPT(pptx: PptxGenJS) {
  const thirdSlide = pptx.addSlide();
  const oeProjects = await fetchOEProjects();
  const dashboard = await fetchDashboardCalculations();

  thirdSlide.addText("Transformational Journey", {
    x: 0.5,
    y: 0.3,
    fontSize: 11,
    fontFace: "Arial",
    bold: true,
    color: iamTheme.palette.primary.main,
  });

  thirdSlide.addText(
    "IAM TOM implementation monitored via IAM Maturity Levels shows progress across Allianz Group",
    {
      x: 0.5,
      y: 0.8,
      fontFace: "Arial",
      fontSize: 20,
      w: 7,
      color: iamTheme.palette.primary.main,
    }
  );
  thirdSlide.addText(
    `as of ${new Date().toLocaleDateString(undefined, { day: "2-digit", month: "long", year: "numeric" })}`,
    {
      x: 7.5,
      y: 0.3,
      fontSize: 10,
      fontFace: "Arial",
      color: iamTheme.palette.primary.main,
    }
  );
  thirdSlide.addText(
    "This slide is missing the comment texts and the graph on the right is missing the Year 2025, Year 2024 target lines, and the square markers for the current month.",
    {
      x: 6.5,
      y: 1,
      w: 4,
      fontSize: 11,
      fontFace: "Arial",
      bold: true,
      color: iamTheme.palette.primary.main,
    }
  );
  const date = new Date();
  const months = getLast12Months(date);
  // Filter to only get quarter months from the last 12 months
  const quarterMonths = months.filter((m) =>
    [2, 5, 8, 11].includes(m.monthNumber)
  );

  const data = [
    {
      name: "Very Low",
      labels: quarterMonths.map((m) => m.month),
      values: quarterMonths.map(
        (m) =>
          oeProjects.data.filter((oe) =>
            oe.attributes.trends?.data.find(
              (t) =>
                t.attributes.month === m.monthNumber &&
                t.attributes.year === m.year &&
                t.attributes.overallMaturity < 1.5
            )
          ).length
      ),
    },
    {
      name: "Low",
      labels: quarterMonths.map((m) => m.month),
      values: quarterMonths.map(
        (m) =>
          oeProjects.data.filter((oe) =>
            oe.attributes.trends?.data.find(
              (t) =>
                t.attributes.month === m.monthNumber &&
                t.attributes.year === m.year &&
                t.attributes.overallMaturity < 2.5 &&
                t.attributes.overallMaturity >= 1.5
            )
          ).length
      ),
    },
    {
      name: "Moderate",
      labels: quarterMonths.map((m) => m.month),
      values: quarterMonths.map(
        (m) =>
          oeProjects.data.filter((oe) =>
            oe.attributes.trends?.data.find(
              (t) =>
                t.attributes.month === m.monthNumber &&
                t.attributes.year === m.year &&
                t.attributes.overallMaturity < 3.5 &&
                t.attributes.overallMaturity >= 2.5
            )
          ).length
      ),
    },
    {
      name: "High",
      labels: quarterMonths.map((m) => m.month),
      values: quarterMonths.map(
        (m) =>
          oeProjects.data.filter((oe) =>
            oe.attributes.trends?.data.find(
              (t) =>
                t.attributes.month === m.monthNumber &&
                t.attributes.year === m.year &&
                t.attributes.overallMaturity < 4.5 &&
                t.attributes.overallMaturity >= 3.5
            )
          ).length
      ),
    },
    {
      name: "Very High",
      labels: quarterMonths.map((m) => m.month),
      values: quarterMonths.map(
        (m) =>
          oeProjects.data.filter((oe) =>
            oe.attributes.trends?.data.find(
              (t) =>
                t.attributes.month === m.monthNumber &&
                t.attributes.year === m.year &&
                t.attributes.overallMaturity >= 4.5
            )
          ).length
      ),
    },
  ];

  // Add the chart to the slide
  thirdSlide.addChart(pptx.ChartType.bar, data, {
    x: 0.5, // X position on slide
    y: 2.1, // Y position on slide
    w: 4, // Width of the chart
    h: 3, // Height of the chart
    barGrouping: "stacked", // Stacked bars
    barDir: "vertical", // Horizontal bars
    dataLabelColor: "000000", // White color for data labels
    dataLabelFontSize: 8, // Font size for data labels
    showLegend: true, // Show legend
    title: "Stacked Bar Chart Example",
    titleFontSize: 18,
    titleAlign: "center",
    catAxisLineShow: false, // Hides the category axis grid lines
    valAxisLineShow: false, // Hides the value axis grid lines
    valGridLine: { color: "FFFFFF" }, // Optional: Explicitly set grid line color to invisible
    chartColors: ["#d7230e", "#ecc02f", "#fdfe3f", "#a7ce5c", "#65ac57"],
    legendPos: "t",
    valAxisLabelColor: iamTheme.palette.primary.main,
    catAxisLabelColor: iamTheme.palette.primary.main,
    showLabel: true,
    showValue: true,
  });
  const quarters = getEndOfQuartersBetweenDates(
    new Date("2023-09-09"),
    new Date("2025-12-31")
  );
  const dataChartLine2 = [
    {
      name: "Actuals",
      labels: quarters.map((q) => q.quarter),
      values: quarters.map(
        (q) =>
          dashboard?.trends?.overallActuals.find(
            (t) => t.year === q.year && t.month === q.number
          )?.maturity ?? null
      ),
    },
    {
      name: "Forecast",
      labels: quarters.map((q) => q.quarter),
      values: quarters.map((q) => {
        let lastActualValue = undefined;
        for (const val of dashboard?.trends?.overallActuals?.slice(-3) ?? []) {
          if (val.month % 3 === 2) {
            lastActualValue = val;
          }
        }
        if (lastActualValue) {
          const existsforecastPeriod =
            q.year > lastActualValue?.year ||
            (q.year === lastActualValue?.year &&
              q.number >= lastActualValue?.month);

          if (existsforecastPeriod) {
            return (
              dashboard?.trends?.overallPlanned.find(
                (t) => t.year === q.year && t.month === q.number
              )?.maturity ?? null
            );
          }
        }
        return null;
      }),
    },
  ];

  thirdSlide.addChart(pptx.ChartType.line, dataChartLine2, {
    x: 4.8, // X position on the slide
    y: 2.1, // Y position on the slide
    w: 4.5, // Width of the chart
    h: 3, // Height of the chart
    showLabel: true,
    valAxisMinVal: 1,
    valAxisMajorUnit: 0.5,
    valAxisMaxVal: 5,
    titleFontSize: 10,
    dataLabelFormatCode: "0.0",
    dataLabelPosition: "t",
    titleColor: iamTheme.palette.primary.main,
    valAxisTitle: "Maturity Level", // Value axis label
    showTitle: false,
    showLegend: true, // Show legend
    legendPos: "t",
    showValAxisTitle: true,
    valAxisLabelColor: iamTheme.palette.primary.main,
    catAxisLabelColor: iamTheme.palette.primary.main,
    valAxisTitleFontSize: 8,
    catAxisLabelFontSize: 8,
    showValue: true,
  });
}
